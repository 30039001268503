import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Got My City" link="https://gotmy.city" bg="linear-gradient(to right, #FFCC00 0%, #00A2FF 100%)" mdxType="ProjectCard">
This project is in response to the COVID-19 pandemic. Helps small business post what they are still offering. Collaboration with MRKT Project.
    </ProjectCard>
    <ProjectCard title="Project 1" link="" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Placeholder project text
    </ProjectCard>
    <ProjectCard title="Project 2" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Placeholder project text
    </ProjectCard>
    <ProjectCard title="Project 3" link="" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Placeholder project text
    </ProjectCard>
    <ProjectCard title="Project 4" link="" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Placeholder project text
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      